import React from 'react'
import { linkTo_UnCryptMailto } from '../../utils/unCryptMail'
import { email_href_mailto_crypted, email_data_privacy_href_mailto_crypted } from '../../constants'

export const dataPrivacyResponsiblePerson = (
    <p className="contact-details">
        Kira Huber<br />
        Im Haberacker 5b<br />
        76227 Karlsruhe<br />
        Tel: <a href="tel:+49 721 98614680">0721/98614680</a><br />
        E-Mail: <button className="link" onClick={() => { linkTo_UnCryptMailto(email_data_privacy_href_mailto_crypted) }}>hallotiergetreu (at) gmail.com</button>
    </p>
)

export const imprintPersonTMG5 = (
    <p className="contact-details">
        Kira Huber<br />
        Im Haberacker 5b<br />
        76227 Karlsruhe<br />
        <br />
        Tel: <a href="tel:+49 721 98614680">0721/98614680</a><br />
        E-Mail: <button className="link" onClick={() => { linkTo_UnCryptMailto(email_href_mailto_crypted) }}>hallotiergetreu (at) gmail.com</button><br />
        <br />
    </p>
)
